import React from "react"

import SEO from "../components/seo"
import Title from "../components/title"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Title>Nie znaleziono</Title>
    <p>Strona której szukasz nie istnieje</p>
  </Layout>
)

export default NotFoundPage

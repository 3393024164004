import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

export default function Title({ children, showAs }) {
  return <StyledTitle as={showAs}>{children}</StyledTitle>
}

Title.defaultProps = {
  showAs: "h1",
}

Title.propTypes = {
  showAs: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const StyledTitle = styled.h1`
  font: 400 3rem/1.2 "Roboto";
  text-transform: uppercase;
  color: ${({ theme }) => theme.secondary};

  @media (min-width: 576px) {
    font: 400 3.5rem/1.3 "Roboto";
  }
`
